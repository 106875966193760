import { ImageContextProvider, useDocumentEvent } from '@mechhive/react';
import { notFound } from '@mechhive/remix';
import type { ShouldRevalidateFunction } from '@remix-run/react';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useLoaderData, useLocation, useRouteError } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { json, redirectDocument, type LinksFunction, type LoaderFunctionArgs } from '@vercel/remix';
import * as acceptLanguageParser from 'accept-language-parser';
import isbot from 'isbot';
import { useEffect } from 'react';
import { z } from 'zod';
import { zx } from 'zodix';
import stylesheet from '~/tailwind.css?url';
import * as gtag from '~/utils/gtags.client';
import { Footer } from './components/Footer';
import { HamburgerMenu } from './components/HamburgerMenu';
import { Header } from './components/Header';
import { NavigationBlocker } from './components/NavigationBlocker';
import { Page } from './components/Page';
import { UserRequest } from './components/user/UserRequest';
import { useLanguageServer } from './hooks/useLanguage.server';
import { useRegionServer } from './hooks/useRegion.server';
import { getUrlPathWithoutLanguage } from './locale';
import { commitSession, getSession } from './session.server';
import { countries, translations, nationalities } from './translations.server';
import { getRequestTimeZone } from './utils/timezone.server';
import { useLanguage } from './hooks/useLanguage';

export const config = { runtime: 'edge' };

const loaderParamsSchema = z.object( {
  lang: z.string().optional()
} )

export const loader = async ( { request, params }: LoaderFunctionArgs ) => {
  const session = await getSession( request.headers.get( 'Cookie' ) );
  const authWrapper = session.get( 'authWrapper' );
  const region = useRegionServer( request );
  const language = useLanguageServer( request );
  const timeZone = getRequestTimeZone( request );

  Sentry.setUser( {
    email: authWrapper?.userAccount?.email ?? 'Anonymous',
    region,
    language,
    timeZone
  } );

  const loaderParams = zx.parseParams( params, loaderParamsSchema );

  const isBot = isbot( request.headers.get( 'user-agent' ) );
  const supportedLanguages = ['en', 'nl', 'fr', 'de'];
  const sessionLanguage = session.get( 'language' );

  const url = new URL( request.url );
  const path = getUrlPathWithoutLanguage( url.pathname, url.search );

  if ( !isBot ) {
    if ( loaderParams.lang == null && sessionLanguage !== 'en' ) {

      if ( sessionLanguage && sessionLanguage !== 'en' ) {
        return redirectDocument( `/${sessionLanguage}${path}` )
      }

      const acceptLanguage = acceptLanguageParser.parse( request.headers.get( 'accept-language' ) ?? '' )?.[0]?.code;
      if ( supportedLanguages.includes( acceptLanguage ) ) {
        session.set( 'language', acceptLanguage );

        if ( acceptLanguage !== 'en' ) {
          return redirectDocument( `/${acceptLanguage}${path}`, {
            headers: {
              'Set-Cookie': await commitSession( session )
            }
          } )
        }
      }
    } else if ( supportedLanguages.includes( loaderParams.lang ?? '' ) ) {
      session.set( 'language', loaderParams.lang );
    }

    if ( sessionLanguage && sessionLanguage !== 'en' && sessionLanguage !== loaderParams.lang ) {
      return redirectDocument( `/${sessionLanguage}${path}` )
    }
    
    if ( sessionLanguage && sessionLanguage === 'en' && loaderParams.lang != null ) {
      return redirectDocument( `${path}` );
    }
  }

  if ( loaderParams.lang != null && !supportedLanguages.includes( loaderParams.lang ) ) {
    throw notFound();
  }

  const userLanguage = loaderParams.lang ?? sessionLanguage ?? 'en';
  return json( {
    commit: process.env.VERCEL_GIT_COMMIT_SHA ?? '1',
    baseUrl: `${url.protocol}//${url.host}`,
    path,
    userLanguage,
    userRegion: region,
    userTimeZone: timeZone,
    translations: translations[ userLanguage ],
    countries: countries[ userLanguage ],
    nationalities: nationalities[ userLanguage ],
    environment: process.env.NODE_ENV,
    gaTrackingId: process.env.GA_TRACKING_ID,
    recaptchaSiteKey: process.env.RECAPTCHA_V2_SITE_KEY,
    trustpilotData: {
      enabled: process.env.TRUSTPILOT_ENABLED === '1',
      score: 4.6,
      totalReviews: 151,
      starsImage: '4.5'
    }
  } );
}

export const shouldRevalidate : ShouldRevalidateFunction = ( { defaultShouldRevalidate, currentUrl, nextUrl } ) => {
  if ( currentUrl.pathname === nextUrl.pathname ) {
    return false;
  }

  return defaultShouldRevalidate;
}

export type RootLoaderData = typeof loader;

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'preconnect', href: 'https://cdn.rewarble.com' },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
];

export function ErrorBoundary() {
  const error = useRouteError();

  if ( isRouteErrorResponse( error ) ) {
    captureRemixErrorBoundaryError( error );
  } else {
    Sentry.captureException( error );
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href="/favicon.ico"
          type="image/x-icon" />
        <link
          rel="icon"
          href="/favicon-16x16.png"
          sizes="16x16" />
        <link
          rel="icon"
          href="/favicon-32x32.png"
          sizes="32x32" />
        <Meta />
        <Links />
      </head>
      <div style={ {
        '--page-color': '#FF0000',
      } }>
        <Page>
          <div className={ 'container mx-auto px-4 flex flex-col gap-24 lg:gap-[100px] items-center' }>
            <div className={ 'md:pt-[80px] flex flex-col items-center gap-[50px]' }>
              <img
                src={ 'https://cdn.rewarble.com/error/icon.png' }
                className={ 'w-[112px] h-[104px]' }
                width={ 112 }
                height={ 104 } 
                alt={ 'Rewarble Error Icon' }
              />
              <div className={ 'flex flex-col items-center gap-2.5' }>
                <div className={ 'text-4xl font-semibold' }>{ isRouteErrorResponse( error ) ? error.status : 'Internal Server Error' }</div>
              </div>
              <div className={ 'flex flex-col gap-[30px] text-xl' }>
                <div className={ 'text-center text-pretty' }>
                  {'This page isn\'t loading like it should, and we\'re sorry about that. Our tech team is already looking into the problem and working to fix it.'}
                </div>
                <div className={ 'text-center text-pretty' }>
                  {'Please try to refresh or go to the home page to solve this issue.'}
                </div>
                <div className={ 'text-center text-pretty' }>
                  {'If the problem persists, please reach out to the online support:'} <strong className={ 'font-semibold' }>support@rewarble.com</strong>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </div>
    </html>
  )
}

const App = () => {
  const loaderData = useLoaderData<typeof loader>();
  const location = useLocation();
  const language = useLanguage();

  useEffect( () => {
    if ( loaderData.gaTrackingId?.length ) {
      gtag.pageview( location.pathname, loaderData.gaTrackingId );
    }
  }, [location, loaderData?.gaTrackingId] );

  return (
    <html lang={ language }>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href="/favicon.ico"
          type="image/x-icon" />
        <link
          rel="icon"
          href="/favicon-16x16.png"
          sizes="16x16" />
        <link
          rel="icon"
          href="/favicon-32x32.png"
          sizes="32x32" />
        <link
          rel="stylesheet"
          type="text/css"
          href={ `https://cdn.rewarble.com/icons/v2/rewarble-icons.css?v=${loaderData.commit}` } />
        <Meta />
        <Links />
      </head>
      <body>
        <UserRequest />
        <>
          { loaderData?.environment === 'development' || !loaderData?.gaTrackingId ? null : (
            <>
              <script
                async
                suppressHydrationWarning
                src={ `https://www.googletagmanager.com/gtag/js?id=${loaderData.gaTrackingId}` }
              />
              <script
                async
                id="gtag-init"
                suppressHydrationWarning
                dangerouslySetInnerHTML={ {
                  __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${loaderData.gaTrackingId}', {
                      page_path: window.location.pathname,
                    });
                  `,
                } }
              />
            </>
          )}
          <ImageContextProvider providers={ [{
            name: 'images',
            baseUrl: 'https://images.rewarble.com',
            optimized: true
          }, {
            name: 'cdn',
            baseUrl: 'https://cdn.rewarble.com'
          }] }>
            <NavigationBlocker />
            <HamburgerMenu />
            <Header />
            <Page>
              <Outlet />
            </Page>
            <div className={ 'container mx-auto px-4 pt-20' }>
              <Footer />
            </div>
          </ImageContextProvider>
          <script
            suppressHydrationWarning
            dangerouslySetInnerHTML={ {
              __html: `window.env = ${JSON.stringify( {
                NODE_ENV: loaderData?.environment
              } )}`,
            } }
          />
        </>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry( App, {
  wrapWithErrorBoundary: false
} );
